import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AboutUS from './pages/AboutUS';
import CaseStudies from './pages/CaseStudies';
import CaseStudiesListing from './pages/CaseStudiesListing';
import ContentLibrary from './pages/ContentLibrary';
import ContentLibraryItem from './pages/ContentLibraryItem';
import CookiePolicy from './pages/CookiePolicy';
import CustomerAndProductPage from './pages/CustomerAndProductPage';
import DataDrivenMarketers from './pages/DataDrivenMarketers';
import LockedCaseStudy from './pages/LockedCaseStudy';
import MailAndPush from './pages/MailAndPush';
import MessageHub from './pages/MessageHub';
import NotFoundPage from './pages/NotFoundPage';
import OmniPersonalization from './pages/OmniPersonalization';
import Overview from './pages/Overview';
import Partners from './pages/Partners';
import PricingPage from './pages/PricingPage';
import PricingShopifyPage from './pages/PricingsShopify';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyShopifyAppTwo from './pages/PrivacyPolicyShopifyAppTwo';
import ProductPage from './pages/ProductPage';
import RelevaAIHub from './pages/RelevaAIHub';
import RelevaHomepage from './pages/RelevaHomepage';
import SocialMedia from './pages/SocialMedia';
import TermsOfUse from './pages/TermsOfUse';
import TrendoCaseStudy from './pages/TrendoCaseStudy';
import AIHubProduct from './pages/plans/AIHubProduct';
import BrandedUrlShortenerProduct from './pages/plans/BrandedUrlShortenerProduct';
import CustomerAndProductPlatformProduct from './pages/plans/CustomerAndProductPlatformProduct';
import FlagShipProduct from './pages/plans/FlagShipProduct';
import MailAndPushProduct from './pages/plans/MailAndPushProduct';
import MessageHubProduct from './pages/plans/MessageHubProduct';
import OmniPersonalizationProduct from './pages/plans/OmniPersonalizationProduct';
import PostPurchaseProduct from './pages/plans/PostPurchaseProduct';
import SocialMediaProduct from './pages/plans/SocialMediaProduct';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<RelevaHomepage />} />
        <Route path="/case-studies-details" element={<CaseStudies />} />
        <Route path="/product-page" element={<ProductPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/data-driven-marketers" element={<DataDrivenMarketers />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/locked-case-study" element={<LockedCaseStudy />} />
        <Route path="/trendo-case-study" element={<TrendoCaseStudy />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/ai-hub" element={<RelevaAIHub />} />
        <Route path="/omni-personalization" element={<OmniPersonalization />} />
        <Route path="/mail-and-push" element={<MailAndPush />} />
        <Route path="/message-hub" element={<MessageHub />} />
        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/customer-and-product-platform" element={<CustomerAndProductPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pricing-2" element={<PricingShopifyPage />} />
        <Route path="/merchant-pro-integration" element={<PricingPage />} />
        <Route path="/case-studies" element={<CaseStudiesListing />} />
        <Route path="/privacy-policy-app" element={<PrivacyPolicyShopifyAppTwo />} />
        <Route path="/plans/flagship-product" element={<FlagShipProduct />} />
        <Route path="/plans/ai-hub-product" element={<AIHubProduct />} />
        <Route path="/plans/omni-personalization-product" element={<OmniPersonalizationProduct />} />
        <Route path="/plans/mail-and-push-product" element={<MailAndPushProduct />} />
        <Route path="/plans/message-hub-product" element={<MessageHubProduct />} />
        <Route path="/plans/social-media-product" element={<SocialMediaProduct />} />
        <Route path="/plans/post-purchase-product" element={<PostPurchaseProduct />} />
        <Route path="/plans/customer-and-product-platform-product" element={<CustomerAndProductPlatformProduct />} />
        <Route path="/plans/branded-url-shortener" element={<BrandedUrlShortenerProduct />} />
        <Route path="/content-library" element={<ContentLibrary />} />
        <Route path="/content-library-details" element={<ContentLibraryItem />} />
        <Route path="/partners" element={<Partners />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
