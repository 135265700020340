import React from 'react';
import './PlansTable.css'; // Import your CSS file for styling

const PlansTable = ({ plans }) => {
  return (
    <>
      <h1 className="compare-plans3">Compare plans</h1>
      <div className="plans1">
        <div className="feature-table-container">
          <div className="row">
            <div className="feature-cell">Features included</div>
            {plans.map((plan, index) => (
              <div className="availability-cell" key={index}>
                {plan.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansTable;
