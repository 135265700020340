import './HomePageHeroTitle.css';

const HomePageHeroTitle = ({ heroTitle, heroDescription, heroYoutube }) => {
  return (
    <section className="releva-homepage-child">
      <div className="frame-container">
        <div className="frame-wrapper">
          <div className="supercharge-your-ecommerce-pro-parent">
            <h1 className="supercharge-your-ecommerce">{heroTitle}</h1>
            <div className="the-only-platform-merging-auto-wrapper">
              <div className="the-only-platform">{heroDescription}</div>
            </div>
            <div className="network-switch">
              <button className="button2">
                <a
                  rel="noopener noreferrer"
                  className="button-name2"
                  target="_blank"
                  href="https://growth.releva.ai/meeting-releva"
                >
                  Book a demo
                </a>
              </button>
              {heroYoutube && (
                <button className="button2 youtubeButton">
                  <a
                    className="button-name2 youtubeButton"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://youtu.be/hvMC3y7pMq0"
                  >
                    Watch Overview
                  </a>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHeroTitle;
