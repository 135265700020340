import './TopThreeFunctionsHomePage.css';

const TopThreeFunctionsHomePage = () => {
  return (
    <section className="benefits-section-wrapper">
      <div className="benefits-section">
        <div className="your-ai-fueled-growth-engine-wrapper">
          <h1 className="your-ai-fueled-growth">Your AI-fueled growth engine</h1>
        </div>
        <div className="frame-parent12">
          <div className="frame-parent13">
            <div className="frame-wrapper11">
              <div className="parent2">
                <div className="div4">01</div>
                <h1 className="ignite-ecommerce-growth">Ignite Ecommerce Growth</h1>
                <div className="ai-powered-personalization-opt">
                  AI-powered personalization optimizes marketing strategies. 53x ROI boost through Releva's precision.
                  Effortless navigation of the dynamic ecommerce landscape.
                </div>
              </div>
            </div>
            <div className="frame-parent14">
              <img
                loading="lazy"
                src="/HomePage/IgniteEcommerceGrowth.webp"
                alt="IgniteEcommerceGrowth"
                title="IgniteEcommerceGrowth"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="frame-parent17">
            <div className="frame-wrapper12">
              <div className="parent3">
                <div className="div5">02</div>
                <h1 className="effortless-marketing-tasks">Effortless Marketing Tasks</h1>
                <div className="releva-simplifies-complex">
                  Releva simplifies complex marketing tasks. Streamlined decision-making with AI. Save time, reduce
                  stress, and focus on strategy.
                </div>
              </div>
            </div>
            <div className="frame-parent18">
              <img
                loading="lazy"
                src="/HomePage/EffortlessMarketing.webp"
                alt="EffortlessMarketing"
                title="EffortlessMarketing"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="frame-parent22">
            <div className="frame-wrapper14">
              <div className="parent4">
                <div className="div6">03</div>
                <h1 className="trust-your-decisions">Trust Your Decisions, Drive Success</h1>
                <div className="precision-in-advertising">
                  Precision in advertising, reaching the right customer at the right time. Cost savings through
                  effective decision-making. Releva empowers confident marketing choices.
                </div>
              </div>
            </div>
            <div className="frame-parent23">
              <img
                src="/HomePage/TrustYourDecisions.webp"
                alt="TrustYourDecisionsDriveSuccess"
                title="TrustYourDecisionsDriveSuccess"
                loading="lazy"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopThreeFunctionsHomePage;
