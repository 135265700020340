import './CustomersShowcase.css';

const CustomersShowcase = () => {
  return (
    <section className="social-proof-section-wrapper">
      <div className="social-proof-section">
        <div className="stack-memory">
          <div className="queue-processor">
            <div className="priority-queue">
              <h1 className="trusted-by-1001">Trusted by 100+ ecommerce brands</h1>
              <div className="edge-connector">
                <div className="from-diverse-industries">
                  From diverse industries and global locations, businesses rely on Releva to drive results
                </div>
              </div>
            </div>
          </div>
          <div className="merchantprointegration">
            <img className="imoteka-icon1" loading="lazy" alt="" src="/imoteka.svg" width="100%" height="100%" />
            <img className="ebag-icon1" loading="lazy" alt="" src="/ebag.svg" width="100%" height="100%" />
            <img className="trendo-icon1" loading="lazy" alt="" src="/trendo.svg" width="100%" height="100%" />
            <img className="adres-icon1" loading="lazy" alt="" src="/adres.svg" width="100%" height="100%" />
            <img className="mercari-icon1" loading="lazy" alt="" src="/mercari.svg" width="100%" height="100%" />
          </div>
        </div>
        {/* <div className="social-proof-section-inner">
          <div className="frame-child1" />
        </div> */}
        <div className="social-proof-section-child">
          <div className="frame-parent4">
            <div className="frame-parent5">
              <div className="parent">
                <div className="div">30</div>
                <div className="wrapper">
                  <h1 className="h11">%</h1>
                </div>
              </div>
              <div className="increase-in-conversion-rates-wrapper">
                <div className="increase-in-conversion">Increase in Conversion Rates</div>
              </div>
            </div>
            <div className="frame-parent6">
              <div className="group">
                <div className="div1">40</div>
                <div className="container">
                  <h1 className="h12">%</h1>
                </div>
              </div>
              <div className="increase-in-average-order-valu-wrapper">
                <div className="increase-in-average">Increase in Average Order Value</div>
              </div>
            </div>
            <div className="frame-parent7">
              <div className="parent1">
                <div className="div2">40</div>
                <div className="frame">
                  <h1 className="h13">%</h1>
                </div>
              </div>
              <div className="increase-in-sales-volumes-wrapper">
                <div className="increase-in-sales">Increase in Sales Volumes</div>
              </div>
            </div>
            <div className="reporting1">
              <div className="footer">
                <div className="c-t-a-background1">
                  <div className="div3">53</div>
                  <div className="times-wrapper">
                    <h1 className="times">times</h1>
                  </div>
                </div>
              </div>
              <div className="return-on-investment">
                <p className="return-on">Return on</p>
                <p className="investment">Investment</p>
              </div>
            </div>
          </div>
        </div>
        <div className="why-releva-container">
          <div className="why-releva2" />
        </div>
      </div>
    </section>
  );
};

export default CustomersShowcase;
