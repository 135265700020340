import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Block4 from '../components/AboutUsPage/Block4';
import Container from '../components/AboutUsPage/Container';
import FrameComponent31 from '../components/AboutUsPage/FrameComponent31';
import FrameComponent32 from '../components/AboutUsPage/FrameComponent32';
import FrameComponent33 from '../components/AboutUsPage/FrameComponent33';
import InputField from '../components/AboutUsPage/InputField';
import InputField1 from '../components/AboutUsPage/InputField1';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import Testimonials from '../components/Testimonials';
import pagesJsonLD from '../constants/pagesJsonLD';
import './AboutUS.css';

const AboutUS = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.replace('#', ''));
      const section = params.get('section');
      if (section) {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [location]);
  return (
    <div className="about-us">
      <Helmet>
        <title>Releva AI | About Us</title>
        <meta
          name="description"
          content="Learn about Releva AI, the innovative company revolutionizing marketing automation. Meet our team, explore our mission, and discover how we're using AI to transform customer engagement and drive business success."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.aboutUsPage)}</script>
      </Helmet>
      <HomePageHeroTitle
        heroTitle="Welcome to Releva!"
        heroDescription="Where we make your eCommerce dream come alive."
      />
      <img className="image-118-icon hero-image" loading="lazy" alt="" src="/AboutUs/HeroImage.webp" />
      <FrameComponent33 />
      <section className="path-finder">
        <div className="distance-calculator">
          <FrameComponent32 />
          <Block4 />
        </div>
      </section>
      <InputField1 />
      <Container />
      <InputField />
      <Testimonials />
      <FrameComponent31 />
    </div>
  );
};

export default AboutUS;
