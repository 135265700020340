import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleLinkClick = () => {
    setOpenDropdown(null);
  };

  return (
    <header className="menu-wrapper">
      <div className="menu1">
        {/* Menu Icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          <img loading="lazy" alt="" src="/menuIcon.svg" />
        </div>
        <div className="menu-child">
          <div className="releva-logo-container">
            <Link className="releva-logo3" to="/">
              <img className="symbol-icon3" alt="Releva AI" src="/symbol.svg" />
              <img className="releva-icon3" loading="lazy" alt="Releva AI" src="/releva.svg" />
            </Link>
          </div>
        </div>
        {/* Menu Items */}
        <nav className={`center-items-wrapper ${menuOpen ? 'open' : ''} ${openDropdown ? 'dropdownOpen' : ''}`}>
          <div className="center-items">
            <div className="menu-item-link">
              <div className={`menu-item-link ${openDropdown === 'why-releva' ? 'dropdown-open' : ''}`}>
                <div className="item-name" onClick={() => toggleDropdown('why-releva')}>
                  Why Releva <span className="dropdown-arrow">&#9662;</span>
                </div>
              </div>
              {openDropdown === 'why-releva' && (
                <div className="dropdown-menu">
                  <Link to="/overview" className="dropdown-item" onClick={handleLinkClick}>
                    E-Commerce owners
                  </Link>
                  <Link to="/data-driven-marketers" className="dropdown-item" onClick={handleLinkClick}>
                    Marketing specialist
                  </Link>
                  <Link to="/case-studies" className="dropdown-item" onClick={handleLinkClick}>
                    Case Studies
                  </Link>
                </div>
              )}
            </div>
            <div className="menu-item-link">
              <div className={`menu-item-link ${openDropdown === 'product' ? 'dropdown-open' : ''}`}>
                <div className="item-name" onClick={() => toggleDropdown('product')}>
                  How it works <span className="dropdown-arrow">&#9662;</span>
                </div>
              </div>
              {openDropdown === 'product' && (
                <div className="dropdown-menu">
                  <Link to="/product-page" className="dropdown-item" onClick={handleLinkClick}>
                    Releva Flagship
                  </Link>
                  <Link to="/ai-hub" className="dropdown-item" onClick={handleLinkClick}>
                    AI Hub
                  </Link>
                  <Link to="/omni-personalization" className="dropdown-item" onClick={handleLinkClick}>
                    Omni Personalization
                  </Link>
                  <Link to="/mail-and-push" className="dropdown-item" onClick={handleLinkClick}>
                    Mail & Push
                  </Link>
                  <Link to="/message-hub" className="dropdown-item" onClick={handleLinkClick}>
                    Message Hub
                  </Link>
                  <Link to="/social-media" className="dropdown-item" onClick={handleLinkClick}>
                    Social Media
                  </Link>
                  <Link to="/customer-and-product-platform" className="dropdown-item" onClick={handleLinkClick}>
                    Customer & Product Platform
                  </Link>
                </div>
              )}
            </div>
            <div className="menu-item-link">
              <Link className="item-name" to="/overview">
                Solutions
              </Link>
            </div>
            <div className={`menu-item-link ${openDropdown === 'plans' ? 'dropdown-open' : ''}`}>
              <div className="item-name" onClick={() => toggleDropdown('plans')}>
                Plans <span className="dropdown-arrow">&#9662;</span>
              </div>
              {openDropdown === 'plans' && (
                <div className="dropdown-menu plans">
                  <Link to="/plans/flagship-product" className="dropdown-item" onClick={handleLinkClick}>
                    Releva Flagship
                  </Link>
                  <Link to="/plans/ai-hub-product" className="dropdown-item" onClick={handleLinkClick}>
                    AI Hub
                  </Link>
                  <Link to="/plans/omni-personalization-product" className="dropdown-item" onClick={handleLinkClick}>
                    Omni Personalization
                  </Link>
                  <Link to="/plans/mail-and-push-product" className="dropdown-item" onClick={handleLinkClick}>
                    Mail & Push
                  </Link>
                  <Link to="/plans/message-hub-product" className="dropdown-item" onClick={handleLinkClick}>
                    Message Hub
                  </Link>
                  <Link to="/plans/social-media-product" className="dropdown-item" onClick={handleLinkClick}>
                    Social Media
                  </Link>
                  <Link to="/plans/post-purchase-product" className="dropdown-item" onClick={handleLinkClick}>
                    Post Purchase
                  </Link>
                  <Link
                    to="/plans/customer-and-product-platform-product"
                    className="dropdown-item"
                    onClick={handleLinkClick}
                  >
                    Customer & Product Platform
                  </Link>
                  <Link to="/plans/branded-url-shortener" className="dropdown-item" onClick={handleLinkClick}>
                    Branded URL Shortener
                  </Link>
                </div>
              )}
            </div>
            <div className="menu-item-link">
              <div className={`menu-item-link ${openDropdown === 'resources' ? 'dropdown-open' : ''}`}>
                <div className="item-name" onClick={() => toggleDropdown('resources')}>
                  Resources <span className="dropdown-arrow">&#9662;</span>
                </div>
              </div>
              {openDropdown === 'resources' && (
                <div className="dropdown-menu">
                  <Link to="/content-library?type=news" className="dropdown-item" onClick={handleLinkClick}>
                    News
                  </Link>
                  <Link to="/content-library?type=blog" className="dropdown-item" onClick={handleLinkClick}>
                    Blog
                  </Link>
                  <Link to="/content-library?type=press%20Release" className="dropdown-item" onClick={handleLinkClick}>
                    Press Release
                  </Link>
                  {/* <Link to="/about-us#section=releva-story" className="dropdown-item" onClick={handleLinkClick}>
                    Technical Documentation
                  </Link>
                  <Link to="/about-us#section=meet-the-team" className="dropdown-item" onClick={handleLinkClick}>
                    User Documentation
                  </Link>
                  <Link to="/about-us#section=releva-vision" className="dropdown-item" onClick={handleLinkClick}>
                    Legal Documentation
                  </Link> */}
                </div>
              )}
            </div>
            <div className="menu-item-link">
              <div className={`menu-item-link ${openDropdown === 'company' ? 'dropdown-open' : ''}`}>
                <div className="item-name" onClick={() => toggleDropdown('company')}>
                  Company <span className="dropdown-arrow">&#9662;</span>
                </div>
              </div>
              {openDropdown === 'company' && (
                <div className="dropdown-menu">
                  <Link to="/about-us" className="dropdown-item" onClick={handleLinkClick}>
                    About
                  </Link>
                  <Link to="/about-us#section=releva-story" className="dropdown-item" onClick={handleLinkClick}>
                    Story
                  </Link>
                  <Link to="/about-us#meet-the-team" className="dropdown-item" onClick={handleLinkClick}>
                    Team
                  </Link>
                  <Link to="/about-us#section=releva-vision" className="dropdown-item" onClick={handleLinkClick}>
                    Vision
                  </Link>
                  <Link to="/partners" className="dropdown-item" onClick={handleLinkClick}>
                    Partners
                  </Link>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div className="items-left">
          <div className="menu-item-link-wrapper">
            <div className="menu-item-link">
              <a
                rel="noopener noreferrer"
                href="https://releva.ai/admin"
                className="item-name"
                aria-label="Link to Releva AI Sign In page"
              >
                Sign in
              </a>
            </div>
          </div>
          <button className="button1">
            <a
              className="button-name1"
              rel="noopener noreferrer"
              target="_blank"
              href="https://growth.releva.ai/meeting-releva"
              aria-label="Link to Releva AI Book A Demo page"
            >
              Book a demo
            </a>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
