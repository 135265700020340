import { useEffect } from 'react';

const HubSpotForm = ({ idForm }) => {
  useEffect(() => {
    // Load HubSpot Forms Embed Script
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      // Initialize HubSpot Form after script is loaded
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '25349229',
          formId: idForm, // Pass idForm directly as a string
          target: '#hubspotForm', // Specify the target div to append the form
        });
      }
    };

    // Append script to the document body
    document.body.appendChild(script);

    // Clean up function
    return () => {
      // Remove the script from the document when the component is unmounted
      document.body.removeChild(script);
    };
  }, [idForm]); // Include idForm in the dependency array if it changes

  return (
    <div id="hubspotForm"></div> // Placeholder for the HubSpot form
  );
};

export default HubSpotForm;
